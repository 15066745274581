import { useContext } from 'react';
import { AppContext } from '../App';

function Key({ val, bigKey, disabled }) {
  const { gameOver, onSelectLetter, onDelete, onEnter } =
    useContext(AppContext);

  const selectLetter = () => {
    if (gameOver.gameOver) return;
    if (val === 'ENTER') {
      onEnter();
    } else if (val === 'DELETE') {
      onDelete();
    } else {
      onSelectLetter(val);
    }
  };

  return (
    <div
      className='key'
      id={bigKey ? 'big' : disabled && 'disabled'}
      onClick={selectLetter}
    >
      {val}
    </div>
  );
}

export default Key;
