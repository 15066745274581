import { useContext, useEffect } from 'react';
import { AppContext } from '../App';

function Letter({ pos, val }) {
  const { board, correctWord, currAttempt, setDisabledLetters } =
    useContext(AppContext);
  const letter = board[val][pos];

  const correct = correctWord.toUpperCase()[pos] === letter;

  const almost =
    !correct && letter !== '' && correctWord.toUpperCase().includes(letter);

  const letterState =
    currAttempt.attempt > val &&
    (correct ? 'correct' : almost ? 'almost' : 'error');

  useEffect(() => {
    if (letter !== '' && !correct && !almost) {
      setDisabledLetters((prev) => [...prev, letter]);
    }
  }, [currAttempt.attempt]);

  return (
    <div className='letter' id={letterState}>
      {letter}
    </div>
  );
}

export default Letter;
